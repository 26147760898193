import Button from 'components/inputs/basic/Button/Button';
import InputCopy from 'components/inputs/composite/InputCopy/InputCopy';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { CredentialsResponse } from './GenerateSnowflakeUser';

interface CredentialsDivProps {
  credentials: CredentialsResponse;
}

const CredentialsDiv = (props: CredentialsDivProps) => {
  const { credentials } = props;

  // Function to create a downloadable file
  const createDownload = (key: string, filename: string) => {
    const blob = new Blob([key], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url); // Clean up the URL
  };

  // Function to handle the download of both files
  const downloadBothKeys = () => {
    if (credentials.public_key !== null && credentials.private_key !== null) {
      createDownload(credentials.public_key, 'public_key.pem');
      createDownload(credentials.private_key, 'private_key.pem');
    }
  };

  return (
    <div className="my-4">
      <Alert variant="warning" className="my-2">
        <div>
          Credentials generated successfully. Please copy the credentials below and save them in a secure
          location. We do not store these credentials and they cannot be retrieved again. However, you
          can generate new Snowflake Users at any time.
        </div>
      </Alert>
      <InputCopy
        isHiddenField={false}
        label="Username"
        value={credentials.username}
        analyticsEvent="IntegrationCredentials UsernameCopy"
        key="username"
        containerClass="py-4"
      />
      {credentials.password && (
        <InputCopy
          isHiddenField={true}
          label="Password"
          value={credentials.password}
          analyticsEvent="IntegrationCredentials PasswordCopy"
          key="password"
          containerClass="py-4"
        />
      )}
      {!!credentials.public_key && !!credentials.private_key && (
        <Button className="mt-4" onClick={downloadBothKeys}>
          Download Public and Private Keys
        </Button>
      )}
      <InputCopy
        isHiddenField={false}
        label="Role"
        value={credentials.role}
        analyticsEvent="IntegrationCredentials RoleCopy"
        key="role"
        containerClass="py-4"
      />
      <div className="mt-6 mb-2 p-4 bg-pri-gray-50 text-pri-gray-700">
        <div className="f-col">
          <div>This user was generated with these commands:</div>
          <div className="f-col text-sec-purple-500">
            <code className="mt-2">CREATE USER {credentials.username};</code>
            <code className="mt-2">
              GRANT ROLE {credentials.role} TO USER {credentials.username};
            </code>
            <code className="mt-2">
              ALTER USER {credentials.username} SET default_role={credentials.role}
            </code>
            <code>default_warehouse={credentials.warehouse}</code>
            {credentials.password && <code>password='Password Given Above'</code>}
            {!!credentials.public_key && <code>rsa_public_key='Public Key Given Above'</code>}
            {credentials.first_name && <code>first_name='{credentials.first_name}'</code>}
            {credentials.last_name && <code>last_name='{credentials.last_name}'</code>}
            {credentials.email && <code>email='{credentials.email}'</code>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CredentialsDiv;
