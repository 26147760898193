import { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

import cls from '../SetupPage.module.css';
import { ByosFormProps } from '../useSetupWarehouse';

import sqlCommands from './SetupBYOSCommands';
import SQLBox from './SQLBox/SQLBox';

interface SetupBYOSViewProps {
  handleSubmit: (values: ByosFormProps) => void;
  handleGoBack: () => void;
  isLoading: boolean;
  error?: string;
}

export default function SetupBYOSView(props: SetupBYOSViewProps) {
  const { isLoading, handleSubmit, handleGoBack, error } = props;
  const SetupSnowflakeSchema = Yup.object().shape({
    account_identifier: Yup.string().required('Required'),
  });

  const [publicKey, setPublicKey] = useState('');
  const [loadingKey, setLoadingKey] = useState(true);
  const [keyError, setKeyError] = useState('');

  useEffect(() => {
    setKeyError('');
    const api = new API();
    api
      .get('/api/byos_generate_key_pair')
      .then((response) => {
        const payload = response.data;
        setPublicKey(payload.public_key);
      })
      .catch((e) => {
        setKeyError('Failed to generate key pair.');
      })
      .finally(() => {
        setLoadingKey(false);
      });
  }, []);

  return (
    <CenteredContainer
      header={
        <>
          <h3 className="text-primary-600 pb-2">ADMIN CREDENTIALS REQUIRED</h3>
          <h1 className="display-sm text-pri-gray-700">
            Follow the instructions to connect your Snowflake data warehouse to Mozart Data
          </h1>
          <Alert variant="info" className="!items-start my-4">
            <p className="mb-4">
              You will need permissions to use the <strong> ACCOUNTADMIN</strong> role on Snowflake.
            </p>

            <p>If you have a Snowflake network policy enabled, please allow these addresses access: </p>
            <span>
              <code className="inline-block w-auto bg-white mr-4 px-2 my-2">34.29.78.127/32</code>
              <code className="inline-block w-auto bg-white px-2 my-2">34.27.39.101/32</code>
            </span>
            <p>
              To learn how to set up a network policy,
              <a
                className="pl-2 text-sec-blue-gray-500 underline"
                href="https://docs.snowflake.com/en/user-guide/network-policies"
                target="_blank"
                rel="noreferrer"
              >
                read these instructions
              </a>
            </p>
          </Alert>
        </>
      }
      loading={false}
      maxWidth="814px"
    >
      <Formik
        initialValues={{ account_identifier: '' }}
        onSubmit={handleSubmit}
        validationSchema={SetupSnowflakeSchema}
      >
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <ol>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>
                  Enter the Snowflake account URL for your organization (
                  <a
                    href="https://help.mozartdata.com/docs/setting-up-data-warehouse#AccountIdentifier"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={cls.setupStepsItemTitleLink}
                    data-track="Signup ClickBYOSAccountIdentifierHelp"
                  >
                    see docs
                  </a>
                  ).
                </p>
                <TextFormikGroup
                  autoFocus
                  type="text"
                  disabled={isLoading}
                  className="w-[445px]"
                  name="account_identifier"
                  placeholder="https://<acct_identifier>.snowflakecomputing.com"
                  label={''}
                  groupClass="mt-4"
                />
              </li>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>
                  Copy and run the SQL below in the Snowflake query runner.
                </p>
                {keyError && <Alert variant="error">{keyError}</Alert>}
                {loadingKey ? (
                  <CenteredSpinner containerClass="mt-4" />
                ) : (
                  <SQLBox sqlCommands={sqlCommands(publicKey)} copyEvent="Signup CopyBYOSSQL" />
                )}
              </li>
            </ol>
            <div className="mt-4 flex justify-end">
              <Button onClick={handleGoBack} disabled={isLoading}>
                Back
              </Button>
              <Button
                className="ml-4"
                type="submit"
                disabled={isLoading || !isValid}
                spinning={isLoading}
              >
                Set Up
              </Button>
            </div>
            {error && (
              <Alert variant="error" className="mt-4">
                {error}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </CenteredContainer>
  );
}
