import GenerateSnowflakeUser from 'components/business_logic/GenerateSnowflakeUser/GenerateSnowflakeUser';

import ExternalLink from '../ExternalLink';
import { fieldValue } from '../fieldUtils';
import { IntegrationLayoutProps } from '../IntegrationSnowflakeConfig';
import Steps from '../Steps';

const SnowflakeLayout = (props: IntegrationLayoutProps) => {
  const { values, integration } = props;
  let url;
  if (values) {
    url = `https://${fieldValue(values, 'full_account_name')}/`;
  }

  return (
    <>
      <Steps>
        <li>
          Go to <ExternalLink href={url || ''}>{integration.uiName}</ExternalLink>.
        </li>
        <li>
          If you do not have a Snowflake username and password you want to use, you can create one below
          by clicking the "Generate Snowflake User" button. Then login with the provided credentials.
        </li>
      </Steps>
      <GenerateSnowflakeUser className="mt-4" />
    </>
  );
};

export default SnowflakeLayout;
